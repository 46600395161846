.acdm-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #202831;
  padding: 0 !important;
}

.acdm-fixed-header {
  position: -webkit-sticky;
  position: sticky;
  top: -1;
  background-color: #202831;
  z-index: 10;
  cursor: default;
}

.acdm-main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.acdm-table {
  width: 100%;
}

[type="radio"]:checked + label:before {
  border: 2px solid transparent;
  background: #2196f3 !important;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 2px solid #5a5a5a;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%;
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: 0.28s ease;
  user-select: none;
}

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: 0.28s ease;
}

.tabs.tabs-fixed-width {
  display: flex;
}

ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none;
}

.tabs {
  position: relative;
  white-space: nowrap;
  margin: 0 auto;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}

.tabs.tabs-fixed-width .tab {
  flex-grow: 1;
}

.tabs .tab {
  text-align: center;
  padding: 0;
  margin: 0;
}

ul:not(.browser-default) li {
  list-style-type: none;
}

.tabs .indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #f6b2b5;
  will-change: left, right;
}

td,
th {
  display: table-cell;
  vertical-align: middle;
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:is(.widget-table tbody tr, .widget-table-size tbody tr):hover {
  cursor: pointer;
  background-color: rgba(32, 92, 246, 0.15);
}

.widget-table tr:nth-child(even),
.widget-table-size tr:nth-child(even) {
  background-color: #202831;
}

.widget-table tr:nth-child(odd),
.widget-table-size tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

.table {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: center;
}

.widget-table tbody tr td {
  font-size: 16px;
  text-align: center;
  word-wrap: break-word;
  border-radius: 0;
}
.widget-table-size tbody tr td {
  font-size: 14px;
  text-align: center;
  word-wrap: break-word;
  border-radius: 0;
}

.widget-table tbody tr td.radio-btn,
.widget-table-size tbody tr td.radio-btn {
  display: flex;
  justify-content: center;
}

.acdm-icon {
  margin-left: 0.3em;
  cursor: pointer;
}

.acdm-icon.active {
  color: #fecc36;
}

.acdm-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.acdm-container .acdm-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-height: 0;
  height: 100%;
}

.acdm-table-wrapper {
  display: inline-block;
  min-height: 0;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.acdm-table-wrapper .acdm-table {
  position: relative;
}

.acdm-filter {
  height: 100%;
  max-height: 100%;
  display: flex;
  padding: 0 1em;
}

.acdm-filter .filter-items-wrapper {
  overflow: auto;
  display: block !important;
  padding: 0 2em 0 0;
  flex: 1;
}

.filter-items-checkbox {
  margin-left: 12px;
  margin-bottom: 3px;
}

.container-input-filter {
  margin-top: 7px;
  margin-bottom: 17px;
}

.acdm-filter .filter-item {
  justify-content: flex-start !important;
}

.acdm-container .filter-by {
  display: flex;
  height: 100%;
  margin: unset !important;
  flex-direction: column;
}

.flight-number:before {
  top: 4px;
  left: -5px;
  width: 8px;
  height: 14px;
}

.widget-table th {
  font-size: 16px;
}
.widget-table-size th {
  font-size: 14px;
}

.widget-table th,
.widget-table-size th {
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #cbcbcb;
  background: #202831;
}

.widget-table tbody tr td {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}

.widget-table-size tbody tr td {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}

.indicator.acdm-tabs {
  transition: all 0.5s ease-out;
  left: 0;
  right: 30%;
  width: 30%;
}

li.tab {
  font-size: 1em;
}

.tables-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-x: auto;
}

.acdm-body .tables-wrapper {
  width: 100%;
}

.tables-wrapper .widget-table,
.tables-wrapper .widget-table-size {
  overflow: auto;
  color: white;
}

.acdm-main .tabs {
  width: 43%;
  float: left;
  margin: unset !important;
}

@media screen and (max-width: 992px) {
  .acdm-main .tabs {
    width: 100%;
    float: unset;
  }
}

.tabs {
  padding: 0 !important;
  height: 3.5em !important;
}

.tabs.tabs-fixed-width .tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs.tabs-fixed-width .tab div {
  width: 100%;
}

.grow-font-size {
  color: black;
  padding: "1em";
  opacity: 1;
  border-radius: 3px;
  font-size: 0.8em;
}

thead {
  border: none;
}

.editable-field-cbcbcb {
  color: #cbcbcb;
  cursor: default;
}

.editable-field-0EAF1E {
  background-color: #0e161e;
  border: 2px solid #0eaf1e;
  cursor: pointer;
}

.editable-field-A31230 {
  background-color: #0e161e;
  border: 2px solid #a31230;
  cursor: pointer;
}

.editable-field-1C8CF3 {
  background-color: #0e161e;
  border: 2px solid #1c8cf3;
  cursor: pointer;
}

.editable-field-0E161E {
  background-color: rgba(14, 22, 30, 1);
  border: 2px solid #0e161e;
  cursor: pointer;
}

.acdm-field {
  display: inline-flex;
  padding: 6px 8px;
  border-radius: 10px;
  justify-content: center;
  width: max-content;
}

.icon-pen {
  font-size: 12px;
  color: white;
  margin-left: 9px;
  margin-top: 1px;
}

.tobt-alert {
  width: 20px;
}

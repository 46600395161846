.pds-container {
  background: #202831;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

.pds-splitter {
  background-color: #312929;
}

.pds-fetch-btn {
  cursor: pointer;
  color: #fecc36;
}

.widget-table {
  font-size: 0.8em;
}

.pds-table {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
}

.pds-fetch-btn:hover {
  text-decoration: underline;
}

.pds-tabs .tabs {
  height: 4em !important;
}

.pds-tabs ul.tabs li.tab button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #939191;
}

.pds-tabs ul.tabs li.tab button div.runway-locations {
  font-size: 0.8em;
}

.pds-table-row .disable {
  color: rgb(252, 66, 66) !important;
}

.red-font {
  color: rgb(252, 66, 66) !important;
}

.pds-constraints-layover .pds-constraints-wrapper .pds-constraint {
  margin: 0.7em 0;
  padding: 0.5em;
  background: #0c0a0a91;
  border-radius: 3px;
}

.pds-main-header {
  font-weight: bold;
}

.rc-time-picker-input{
    background: transparent;
    width: 200%;
    font-family:'Proxima Nova';
    text-transform: uppercase;
  }
  
  .rc-time-picker input[type="text"]{
    width: 100%;
    border: none;
    border-bottom: none;
    margin-top: 9px;
    margin-left: 27px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .rc-time-picker input:focus{
    border-bottom: none !important;
    box-shadow: none !important;
  }
  
  .rc-time-picker input[type="text"]:disabled {
    -webkit-text-fill-color: #212831;
    background: none;
    cursor: none;
}
  .rc-time-picker-clear{
    display: none;
  }

  .rc-time-picker-panel {
    z-index: 1500;
  }

  .rc-time-picker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 16px;
    text-align: left;
    background-color: #303B46 !important;
    border-radius: 12px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    line-height: 1.5;
    width: 260px;
    top: 55px;
    color: white;
    width: 313px;
    font-family:'Proxima Nova';
  }

  .rc-time-picker-panel-input-wrap {
    display: none;
  }
  
  .rc-time-picker-panel-select li:hover{
  background-color: #205CF6 !important;
}
.rc-time-picker-panel-select:last-child>ul{
  display: flex;
}
.rc-time-picker-panel-select:last-child>ul>li{
  margin-right: 12px;
  padding-top: 2px;
  text-align: center;


}
.rc-time-picker-panel-select:last-child{
  width: fit-content;
}

  .rc-time-picker-panel-select li{
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  width: 55px;
  padding: 0 0 0 0;
  }
  .rc-time-picker-panel-select:not(:last-child)>ul>li{
    width: 46px;
    padding-top: 2px;
    text-align: center;
  }
  .rc-time-picker-panel-select:not(:last-child){
    margin-right: -8px;
  }

  .rc-time-picker-panel-select::-webkit-scrollbar {
    display: none;
  }
   .rc-time-picker-panel-select {
    float: left;
    font-size: 13px;
    border: none !important;
    border-width: 0 1px;
    margin-left: -1px;
    box-sizing: border-box;
    width: 56px;
     max-height: 250px !important;
    position: relative;
    margin-top: 12px;
  } 

  .rc-time-picker-panel-select:first-child {
    margin-left: 21px !important;
    margin-right: 10px;
    
  }
  .rc-time-picker-panel-select:last-child {
    margin-left: 42px !important    
  }
  
  .rc-time-picker-panel-select ul li {
    list-style-type: none;
    background: #141E29;
    margin-bottom: 9px;
    border-radius: 14px;
    height: 28px;
  }
  
  li.rc-time-picker-panel-select-option-selected {
    background: #205CF6 !important;
    font-weight: bold;
    border-radius: 90px;
  }
  .rc-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 10px;
  }
  
  .rc-time-picker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    border-radius: 12px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25) !important;
      background-clip: padding-box;
    border: none !important;
    line-height: 1.5;
  } 

.background {
  background-color: #0f161e;
}

.overview-header {
  display: flex;
  display: flex;
  align-items: center;
  width: 97%;
  margin: 16px auto 0 auto;
  padding-right: 4px;
}

.overview-share {
  display: flex;
  align-items: center;
}

.header-icons {
  margin-right: auto;
}

.content-view {
  display: flex;
  margin: 23px auto;
  width: 97%;
}

.view-item {
  width: 50%;
  background: #141b22;
  padding: 11px 4px;
  border-radius: 10px;
}
.view-item:not(:last-child) {
  margin-right: 30px;
}
.view-item img {
  height: 100%;
  width: 97%;
}

.container-kpi {
  overflow: auto;
}

.text-heading {
  font-size: 14px;
  font-weight: 800;
  color: #205cf6;
}

.overview-heading {
  background-color: #12244c;
  width: 76px;
  height: 28px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  cursor: pointer;
}

.add-text {
  font-size: 20px;
  font-weight: 800;
}

.add-icon {
  width: 26px;
  height: 26px;
  background-color: #273645;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.layer-icon {
  width: 26px;
  height: 26px;
  background-color: #273645;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.export-text {
  margin-right: 9px;
}

.export {
  font-size: 14px;
  margin-right: 33px;
  cursor: pointer;
}

.crop {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  margin-right: 12px;
  background-color: #2a3d52;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.share {
  width: 72px;
  height: 38px;
  background: #2a3d52;
  border-radius: 8px;
  margin-right: 12px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.add-report {
  width: 144px;
  height: 38px;
  background: #2a3d52;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.days {
  display: flex;
  align-items: center;
  border: 1px solid #2a3d52;
  background-color: #131b22;
  margin-left: 20px;
  padding: 10px 16px;
  border-radius: 15px;
  margin-right: 24px;
  cursor: pointer;
}

.container-days {
  display: flex;
  align-items: center;
  margin-top: 39px;
}

.day-text:not(:last-child) {
  margin-right: 32px;
}

.airport-text {
  width: 144px;
  height: 38px;
  background: #2a3d52;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

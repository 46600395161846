[data-dir="col"] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.atcw-body > [data-layout="col"] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100% !important;
}

[data-layout="row"] {
  -webkit-box-align: basline;
  -webkit-align-items: basline;
  -ms-flex-align: basline;
  align-items: basline;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

[data-layout="row"] {
  margin: 0.5em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  overflow: visible;
  display: flex;
  justify-content: center;
}

[data-layout="row"] {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.atcw-section-unit.total,
.atcw-section-unit.alt,
.atcw-section-unit .atcw-rnwv-range.total,
.atcw-wind-profile-title.total,
.atcw-wind-profile-title.alt,
.atcw-wind-profile-title .atcw-rnwv-range.total,
.atcw-section-title.total,
.atcw-section-title.alt,
.atcw-section-title .atcw-rnwv-range.total,
.wind-max-speed.total,
.wind-max-speed.alt,
.wind-max-speed .atcw-rnwv-range.total,
.wind-min-speed.total,
.wind-min-speed.alt,
.wind-min-speed .atcw-rnwv-range.total,
.atcw-rnwv-range.total,
.atcw-rnwv-range.alt,
.atcw-rnwv-range .atcw-rnwv-range.total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  font-size: 1.3em;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #cbcbcb;
}

.temp.Temperature .atcw-section-unit.total {
  font-size: 3em;
}

.atcw-section-title.sec-head {
  font-size: 0.8em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a8e9d;
}

.atcw-section-title {
  font-size: 1em;
  text-overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
}

.atcw-section-inner {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-weather-data {
  width: 100%;
  -ms-flex: 1 1;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.filter-weather-data-item.data-wrapper {
  color: #4d4dd6;
}

.atcw-section-unit {
  line-height: 2em;
  font-size: 1.2em;
}

.atcw-section-unit-of-mes {
  font-weight: 600;
  font-size: 0.6em !important;
  transform: translate(3px, -6px);
}

.weather-filter-body .filter-weather-data-item input:checked + .slider {
  background: #0e307b !important;
}

.atcw-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

.weather-tabs.atcw-body {
  box-shadow: 2px 2px 0 2px #88888845 !important;
}

.wind-container {
  flex: 2 !important;
}

.wind-container .atcw-winds-row .wind-speed,
.wind-container .atcw-winds-row .wind-gust,
.wind-container .atcw-winds-row .wind-speed,
.wind-container .atcw-winds-row .wind-gust,
.wind-container .atcw-winds-row .wind-speed,
.wind-container .atcw-winds-row .wind-gust,
.wind-container .atcw-winds-row .wind-speed,
.wind-container .atcw-winds-row .wind-gust {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.atcw-winds-row .atcw-section-unit-wrap {
  margin-left: 0.3em;
}

.wind-container .atcw-winds .atcw-wind.wind-min-speed,
.wind-container .atcw-winds .atcw-wind.wind-max-speed {
  display: none;
}

.widget {
  display: flex;
}

.compass-widget.wind-direction {
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wind-direction {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 0;
}

.compass-widget {
  position: relative;
  display: inline-block;
  width: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.atcw-section-inner .atcw-humidities,
.atcw-section-inner .atcw-dews,
.atcw-section-inner .atcw-temps,
.atcw-section-inner .atcw-winds,
.atcw-section-inner .atcw-rnwv-ranges,
.atcw-section-inner .atcw-cloud-heights {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
  min-height: 0;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}

.wind-profile .atcw-section-inner {
  align-items: normal;
}

.wind-container .atcw-winds .atcw-winds-row,
.wind-container .atcw-winds .atcw-wind {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.atcw-winds {
  display: flex;
  flex-direction: column;
}

.atcw-wind-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-height: 0;
}

.atcw-winds-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.splitter {
  color: black;
  width: 80%;
}

.atcw-wind-profile-categories {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: no-wrap;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
  widows: 100%;
}

.atcw-section-unit,
.atcw-wind-profile-title,
.atcw-section-title,
.wind-max-speed,
.wind-min-speed,
.atcw-rnwv-range {
  display: none;
}

.atcw-wind-profile-title {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 0;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

.atcw-rnwv-range.row.first,
.atcw-wind-profile.row.first {
  border-top: none;
}

.atcw-wind-profile.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-flex-wrap: no-wrap;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 0;
  margin-bottom: 0 !important;
  flex-grow: 2;
}

.row.atcw-wind-profile {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1em;
  /* flex: 1 1; */
}

.row.atcw-wind-profile .atcw-stat-dot-wrap,
.row.atcw-wind-profile span,
.row.atcw-wind-profile h3 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 0;
}

.atcw-stat-dot-wrap {
  width: 16px !important;
  max-width: 16px;
  border-bottom: none !important;
}

.atcw-stat-dot.red {
  background: #d23c2a;
}

.atcw-stat-dot {
  border-radius: 30em;
  width: 0.6em !important;
  height: 0.6em;
  border-bottom: none;
}

.atcw-stat-dot.gray {
  background: #8b8e8f;
}

.atcw-stat-dot.yellow {
  background: #fac76f;
}

.atcw-wind-profiles {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #202831;
}

.atcw-section-inner .atcw-rnwv-ranges {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 80%;
}

.atcw-rnwv-range {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 0;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.atcw-rnwv-range.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5em;
}

h1.atcw-section-unit.sub {
  line-height: 2em;
}

.rnwv-range {
  -webkit-box-flex: 3.2 !important;
  -webkit-flex-grow: 3.2 !important;
  -ms-flex-positive: 3.2 !important;
  flex-grow: 3.2 !important;
}

.pressure-container .atcw-section-inner {
  padding: 1em;
}

.cloud-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.weather-icon {
  color: white;
  margin: 0 0.3em;
  font-size: 3.2em;
}

.weather-container .row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #202831;
}

.weather-container.weahter-tabs.atcw-body {
  display: flex;
  flex-direction: column;
  color: white;
}

.indicator {
  transition: all 0.5s ease-out;
  left: 0;
  right: 75%;
}

li.tab {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .weather-container.weather-tabs.atcw-body {
    font-size: 0.8em !important;
  }

  .wind-container .wind {
    display: flex;
  }

  .wind-container > div {
    flex: 1;
  }

  hr.splitter {
    margin-bottom: 1em;
  }

  .pressure-container,
  .cloud {
    font-size: 0.6em;
  }

  .cloud-container {
    font-size: 0.6em;
  }

  .atcw-wind-profile.row {
    margin: 1em 0;
  }
}

.weather-container .tabs {
  background: transparent;
  color: white;
  width: 60% !important;
}

.weather-container {
  background: #202831;
}

.date {
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a8e9d;
}

.atcw-winds h2.atcw-section-title.total {
  font-size: 0.8em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a8e9d;
}

.atcw-rnwv-range.total .atcw-section-unit.sub.total {
  display: flex;
  font-size: 0.9em;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #cbcbcb;
}

.atcw-rnwv-range.total .rnw-range-label {
  font-size: 0.8em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a8e9d;
}

.atcw-rnwv-range.total.odd {
  background: #202831;
}

.atcw-rnwv-range.total.even {
  background: #2a2934;
}

h2.atcw-wind-profile-title.alt {
  font-size: 0.8em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a8e9d;
}

.rnwv-range .atcw-section-title.sec-head,
.wind-profile .atcw-section-title.sec-head {
  color: #cbcbcb;
  font-size: 1em;
  font-weight: bold;
}

.wind-container .wind > div.atcw-section-inner .atcw-winds {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
}

.wind-value {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.weather-filter-container {
  background-color: #000000ed;
  display: flex;
  width: 75%;
  max-width: 100%;
  height: 90%;
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 1em;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.weather-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  font-size: 1.5em;
  min-height: 0;
}

.weather-filter-sub-header-2 {
  font-size: 0.68em;
  color: grey;
  margin-bottom: 1em;
}

.weather-filter-body {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  max-height: 100%;
  min-height: 0;
}

.filter-weather-entry-data {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  border-bottom: 1px solid grey;
  min-height: 0;
}

.filter-weather-data-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
  margin: 0.6em 0;
  min-height: 0;
}

.hide-weather-filter {
  display: none;
}

.filter-weather-data-item input:checked + .slider {
  background: #fecc36 !important;
}

.filter-weather-data-item .record {
  font-size: unset !important;
  margin-bottom: 11px !important;
}

.weather-filter-btn {
  cursor: pointer;
}

.weather-filter-save-btn {
  text-align: right;
  font-size: 1.2em;
  color: #9e9e9e;
  padding: 0 0.5em;
  cursor: pointer;
}

.weather-filter-save-btn:hover,
.weather-filter-close-btn:hover {
  color: white;
}

.weather-filter-close-btn {
  cursor: pointer;
  color: #9e9e9e;
}

:root {
  --background-color: #0e161e;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../public/assets/fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("../public/assets/fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

html,
body {
  font-family: "Proxima Nova";
  height: 100%;
  overflow-x: hidden;
  display: flex;
  background: #eaeaea;
  box-sizing: border-box;
}

body.no-focus-outline *:focus {
  outline: none !important;
}

html,
body,
.container {
  width: 100%;
  margin: 0;
}

.emma-body {
  overflow: auto;
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 0;
  width: 100%;
  max-width: 100%;
}

.emma-body:not(.login-body) {
  background: #0e161e;
  margin-top: 4em;
}

@media screen and (max-width: 992px) {
  .emma-body {
    flex-direction: column;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
    color: black;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.filter-icon {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='20' viewBox='0 0 16 20' fill='none'%3E%3Cpath d='M12.9999 8.73242L12.9999 18.9999' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.05492 16.2401C1.91493 16.2401 0.999926 15.3176 0.999926 14.1851C0.999926 13.0451 1.92243 12.1301 3.05493 12.1301C4.19492 12.1301 5.10992 13.0526 5.10992 14.1851C5.10992 15.3176 4.19492 16.2401 3.05492 16.2401Z' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.05493 12.0775L3.05493 1' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.05493 19L3.05493 16.24' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.9999 4.59253C14.1399 4.59253 15.0549 5.51503 15.0549 6.64753C15.0549 7.78003 14.1324 8.70253 12.9999 8.70253C11.8599 8.70253 10.9449 7.78003 10.9449 6.64753C10.9374 5.51503 11.8599 4.59253 12.9999 4.59253Z' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.9999 1L12.9999 4.5925' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  height: 16px;
  padding-left: 5px;
}

.show-hide-column-icon {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16' viewBox='0 0 18 16' fill='none'%3E%3Cpath d='M5.25379 14.5476H1.52416C1.2352 14.5476 1 14.3124 1 14.0235V1.52416C1 1.2352 1.2352 1 1.52416 1H5.24707C5.53603 1 5.77123 1.2352 5.77123 1.52416V14.0167C5.77796 14.3124 5.54275 14.5476 5.25379 14.5476Z' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.604 14.5476H8.69451C8.40555 14.5476 8.17035 14.3124 8.17035 14.0235V1.52416C8.17035 1.2352 8.40555 1 8.69451 1H16.5973C16.8863 1 17.1215 1.2352 17.1215 1.52416V14.0167C17.1282 14.3124 16.893 14.5476 16.604 14.5476Z' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  height: 17px;
}

.export-icon {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='18' viewBox='0 0 21 18' fill='none'%3E%3Cpath d='M10.4999 7.86375L10.4999 16.8887' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.6454 14.7433L10.5 16.8887L8.35455 14.7433' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.6454 12.7087H15.2104H16.7858C18.5592 12.7087 20 11.2758 20 9.5025C20 7.72917 18.5592 6.29625 16.7858 6.29625C16.7304 6.29625 16.6671 6.29625 16.6117 6.30417C16.6354 6.12208 16.6433 5.93208 16.6433 5.74208C16.6433 3.12167 14.5138 1 11.8933 1C9.80333 1 8.02208 2.35375 7.38875 4.23C6.985 3.77875 6.39917 3.50167 5.75 3.50167C4.515 3.50167 3.5175 4.49917 3.5175 5.73417C3.5175 5.78167 3.5175 5.82917 3.52542 5.86875C2.06083 6.29625 1 7.63417 1 9.2175C1 11.1412 2.5675 12.7008 4.49125 12.7008H8.19625' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  height: 17px;
}

.print-icon {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M3.6477 13.6807L2.07521 13.6538C1.48384 13.6538 1 13.1767 1 12.5988V5.48898C1 4.91105 1.48384 4.43393 2.07521 4.43393H16.0529C16.6443 4.43393 17.1281 4.91105 17.1281 5.48898V12.5988C17.1281 13.1767 16.6443 13.6538 16.0529 13.6538H14.3729' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.6068 10.2468C13.9898 10.2468 14.3057 10.5559 14.3057 10.9322V13.6539V16.4225C14.3057 16.7988 13.9898 17.108 13.6068 17.108H4.52127C4.13823 17.108 3.82239 16.7988 3.82239 16.4225V13.6539V10.9322C3.82239 10.5559 4.13823 10.2468 4.52127 10.2468H13.6068V10.2468Z' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.82239 4.43395V1.68545C3.82239 1.30912 4.13823 1 4.52127 1H13.6068C13.9898 1 14.3057 1.30912 14.3057 1.68545V4.43395' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.0919 6.89347C15.0919 7.06148 14.9575 7.19588 14.7895 7.19588C14.6215 7.19588 14.4871 7.06148 14.4871 6.89347C14.4871 6.72547 14.6215 6.59107 14.7895 6.59107C14.9575 6.59107 15.0919 6.72547 15.0919 6.89347Z' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  height: 17px;
}

.node {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 3px;
}

.node:hover {
  background-color: rgb(220, 245, 243);
  cursor: pointer;
}

.info,
.node {
  padding: 2px 10px 2px 5px;
  font: 14px Helvetica, Arial, sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal {
  max-height: 80%;
}

.info {
  padding: 10px;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: #0e161e;
}

.compass {
  display: block;
  width: 8em;
  height: 8em;
  border-radius: 100%;
  position: relative;
  border: solid 1px #979797;
}

.compass::before {
  position: absolute;
  text-align: center;
  width: 100%;
  content: "N";
  color: rgb(139, 142, 143);
  font-weight: 600;
  top: 0px;
  left: 0%;
}

.compass .direction {
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 100%;
  position: relative;
}

.compass .direction p {
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  color: rgb(139, 142, 143);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.3em;
}

.compass .direction p span {
  display: block;
  line-height: normal;
  text-transform: uppercase;
  font-weight: normal;
}

.compass .arrow {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.compass .arrow::after {
  content: "⮝";
  width: 0;
  height: 0;
  position: absolute;
  top: -0.8em;
  left: 43%;
  z-index: 99;
  color: yellow;
  font-size: 1.5em;
}

.compass .arrow.nne {
  transform: rotate(22.5deg);
}

.compass .arrow.ne {
  transform: rotate(45deg);
}

.compass .arrow.ene {
  transform: rotate(67.5deg);
}

.compass .arrow.e {
  transform: rotate(90deg);
}

.compass .arrow.ese {
  transform: rotate(112.5deg);
}

.compass .arrow.se {
  transform: rotate(135deg);
}

.compass .arrow.sse {
  transform: rotate(157.5deg);
}

.compass .arrow.s {
  transform: rotate(180deg);
}

.compass .arrow.ssw {
  transform: rotate(202.5deg);
}

.compass .arrow.sw {
  transform: rotate(-135deg);
}

.compass .arrow.wsw {
  transform: rotate(-114.5deg);
}

.compass .arrow.w {
  transform: rotate(-90deg);
}

.compass .arrow.wnw {
  transform: rotate(-69.5deg);
}

.compass .arrow.nw {
  transform: rotate(-45deg);
}

.compass .arrow.nnw {
  transform: rotate(-24.5deg);
}

.activeFlight {
  background: #205CF6 !important;
}

.app.active {
  position: relative;
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.login-page-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.login-page-container.login-page {
  background-image: url("../public/assets/images/login.png");
  background-position: 37%, 50%;
}

.app.active .main-header {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 4em;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.app.active .main-header .nav.normal-mode,
.app.active .main-header .layouts-grid,
.app.active .main-header .nav.normal-mode,
.app.active .main-header .user-grid {
  background: #0e161e;
}

.app.active .main-header .layouts-grid,
.app.active .main-header .user-grid {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.app.active .main-header .nav {
  line-height: normal !important;
  height: 100% !important;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0e161e;
}

@media screen and (max-width: 992px) {
  .app.active .main-header .nav {
    font-size: 0.8em;
  }
}

.nav .nav-wrapper {
  height: auto !important;
  max-height: 70px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.main-header .nav .nav-wrapper.container img.emma-logo {
  width: 5em;
  fill: white;
  cursor: pointer;
}

.layout-type.manage-layout-btn {
  padding: 1em;
}

.main-header div.layouts-grid,
.main-header div.user-grid {
  position: absolute;
  max-height: 0;
  z-index: 99;
  overflow: hidden;
  transition: all 0.7s;
  color: grey;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-header div.layouts-grid {
  top: 4em;
}

.main-header div.user-grid {
  top: 4.5em;
  right: 0;
}

.main-header div.layouts-grid .layout-grid-select-header,
.main-header div.user-grid .user-grid-select-header {
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 1px;
  font-size: 0.9em;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 1em;
}

.main-header div.layouts-grid.show,
.main-header div.user-grid.show {
  padding: 1em;
  visibility: visible;
  max-height: 20em;
  transition: all 0.7s;
}

.main-header div.layouts-grid .layouts-grid-bar > div:hover,
.main-header div.user-grid .user-grid-bar > div:hover {
  box-shadow: 0px 2px 0px grey;
  transition: box-shadow 0.3s ease-in-out;
}

.alert-action {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  align-items: flex-end;
}

.add-new-alert {
  width: 37%;
}

.header-info-breadcrumbs > nav {
  background-color: inherit !important;
  box-shadow: none;
  color: #909fba;
  height: auto;
}

.header-info-breadcrumbs > nav > ol > li {
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
}

.header-btns {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  min-width: 22%;
  align-items: center;
}

.header-btns > div {
  flex: 1 1 auto;
  margin: 5px;
}

.container-role {
  width: fit-content;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.container-role .user-name {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
}

.role-name {
  font-size: 12px;
  color: #909fba;
  cursor: pointer;
}

.main-header div.layouts-grid .layouts-grid-bar,
.main-header div.user-grid .user-grid-bar {
  cursor: pointer;
  overflow: auto;
  margin-right: 0.5em;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.main-header div.layouts-grid .layout-grid,
.main-header div.user-grid .selection {
  width: 2em;
  height: 2em;
  color: grey;
  margin: 0.5em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.main-header div.user-grid .selection {
  width: 100%;
  justify-content: flex-start;
  margin: 0.5em 0;
}

.profile-link {
  text-decoration: none;
}

.layout-grid div {
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.65em;
}

.main-container {
  width: 100%;
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #0e161e;
}

.btn-action {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.9;
  color: #4375ef;
  display: inline-block;
  cursor: pointer;
}

.container-rating {
  transform: scale(2);
  display: block;
  text-align: center;
}

.rating {
  height: 5px;
}

.main-container.normal {
  width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  margin-left: 60px;
  height: 100%;
  flex: 1;
}

.app.active .sub-main-container {
  height: 100%;
  display: flex;
  flex: 1;
  min-height: 0;
  justify-content: center;
}

.widgets-wrapper {
  flex: 1;
  width: 100%;
  max-width: 100%;
  min-height: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
}

.widgets-wrapper .layout-wrapper {
  width: 100%;
  max-width: 100%;
  flex: 1;
  background: #1c1b1f;
}

.editable-widgets-wrapper .layout-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.editable-widgets-wrapper .layout-wrapper.column {
  flex-direction: column;
}

.layout-controller {
  display: flex;
  margin: 0.3em;
  justify-content: space-between;
  height: 123px;
  flex: 0 0;
}

.manage-btn {
  height: 93px;
  display: flex;
  width: 15%;
  min-width: 15%;
}

.action-btn {
  height: 93px;
  display: flex;
  width: 22%;
  min-width: 14%;
}

.width-btn {
  width: 50%;
}

.widgets-select {
  display: flex;
  height: 123px;
  align-items: center;
}

.editable-widgets-wrapper {
  width: 80%;
  height: 70%;
  margin: 2em auto;
}

.widgets-wrapper > *:not(.Toastify) {
  display: flex;
  flex: 1;
  min-height: 0;
  flex-wrap: wrap;
}

.widgets-wrapper > *:not(.Toastify).column {
  flex-direction: column;
}

.weather-container,
.asmgcs-container,
.acdm-container,
.bhs-container,
.pds-container {
  flex: 1;
  min-height: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.row {
  width: 100% !important;
  margin-bottom: 0;
}

.layout-wrapper {
  flex-wrap: wrap;
  height: 100%;
}

.layout-wrapper > div {
  position: relative;
  padding: 0.3em;
}

.editable-widgets-wrapper > div {
  height: 100%;
}

.editable-widgets-wrapper .layout-block.edit > div {
  pointer-events: none;
  opacity: 0.3;
}

.layout-wrapper .layout-block {
  justify-content: center;
  align-items: center;
  display: flex;
}

.widgets-wrapper .fullscreen-container {
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.block-resize-icon {
  position: absolute;
  top: 15px;
  z-index: 7;
  right: 27px;
  padding: 0.5em;
  background: white;
  border-radius: 3px;
  color: white;
  height: 2em;
  margin-top: 1px;
  display: flex;
  background: none;
  cursor: pointer;
}

.block-resize-icon:hover {
  background: #6473e0;
  color: white;
}

.layout-wrapper .edit {
  border: 1px solid #aaaaaa;
  margin: 0.3em;
}

.widget-selector {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 999;
  margin-left: 2px;
  margin-right: 8px;
}

.widget-selector .widget-alias {
  cursor: pointer;
}

.widget-selector span:not(.widget-alias) {
  margin: 0 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;
}

.layout-controller > * {
  justify-content: center;
  align-items: center;
}

.widget-icon-container {
  width: 66px;
  height: 63px;
  background: #2f373d;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs .tab {
  height: auto;
  line-height: 1;
}

.tabs {
  height: auto;
  padding: 1em 0;
}

.tabs .tab a {
  font-size: 1.5em;
}

.layout-controller .fa-arrows-alt {
  margin-right: 1em;
  transform: rotate(45deg);
}

.widget-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  padding: 0.2em;
  color: black;
  max-width: 78px;
}

.widget-icon * {
  pointer-events: none;
}

.widget-icon .widget-alias {
  font-size: 0.5em;
  justify-self: center;
  font-size: 11px;
  line-height: 14px;
  color: #cbcbcb;
  font-weight: 600;
  margin-top: 8px;
}

.dragged-item {
  position: absolute;
  z-index: 999;
  width: 50px;
  height: 67px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-icon .fa {
  color: white;
  justify-self: center;
  align-self: center;
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 0.2em;
}

.widget-icon.disable {
  color: grey;
}

.widget-icon.disable .fa {
  color: grey;
}

#root,
.app-root {
  display: flex;
  flex: 1;
  min-height: 0;
  height: auto !important;
  width: 100%;
  max-width: 100%;
}

.widget-header {
  height: 2.5em;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  width: 96%;
}

.widget-acdm-header {
  height: 2.5em;
  font-size: 12;
  font-weight: bold;
  margin-top: 23px;
  margin-left: 19px;
  color: white;
}

.widget-config-btn {
  border-radius: 50%;
  height: 2em;
  width: 2em;
  padding: 1em;
  align-items: center;
  z-index: 777;
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  color: white;
}

.widget-config-weather {
  position: absolute;
  top: 12px;
  z-index: 7;
  right: 50px;
  padding: 0.5em;
  background: white;
  border-radius: 3px;
  color: white;
  height: 2em;
  margin-top: 1px;
  display: flex;
  background: none;
  font-size: 16px;
}

.postion {
  position: relative;
  top: 42px;
}

@media (max-width: 992px) {
  .postion {
    right: -76px;
    top: 48px;
  }
}

.ellipsis-vertical-config {
  border-radius: 50%;
  height: 12px;
  width: 2em;
  padding: 1em;
  align-items: center;
  z-index: 777;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-size: 17px;
}

.widget-config {
  right: 52px;
}

.ellipsis-vertical-position {
  right: 17px;
}

.widget-config-filter {
  border-radius: 50%;
  right: 86px;
  position: absolute;
  height: 2em;
  width: 5px;
  padding: 1em;
  align-items: center;
  z-index: 777;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
}

.widget-config-search {
  border-radius: 50%;
  right: 121px;
  position: absolute;
  height: 2em;
  width: 2em;
  padding: 1em;
  align-items: center;
  z-index: 777;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  margin-right: 10px;
}

.widget-config-acdm {
  border-radius: 50%;
  height: 2em;
  width: 2em;
  padding: 1.3em;
  align-items: center;
  z-index: 777;
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.widget-config-settings {
  position: absolute;
  top: 8px;
  right: 2px;
}

.widget-config-filter-header {
  border-radius: 50%;
  position: relative;
  height: 2em;
  width: 5px;
  padding: 1em;
  align-items: center;
  z-index: 777;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
}

.resize-widget-config-acdm {
  z-index: 777;
  cursor: pointer;
  position: absolute;
  top: 27px;
  right: 0px;
  font-size: 1.2em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-color: #0a2a7a;
}

@media screen and (max-width: 992px) {
  .widget-config-btn {
    right: 21px;
    font-size: 1.1em;
  }

  .widget-config-weather {
    right: 0;
    font-size: 1.1em;
  }

  .widget-config-acdm {
    right: 49px;
    font-size: 1.4em;
  }

  .widget-config {
    right: 8px;
    font-size: 1.4em;
  }

  .widget-config-filter {
    right: 30px;
    font-size: 1.4em;
  }

  .widget-config-search {
    right: 70px;
    font-size: 1.4em;
  }

  .widget-config-filter-header {
    right: 30px;
    font-size: 1.4em;
  }
}

.widget-table,
.widget-table-size {
  font-size: 1em;
}

th.acdm-fixed-header {
  text-align: center !important;
}

.widget-table:not(.acdm-table) td,
.widget-table-size:not(.acdm-table) td,
th {
  padding: 4px !important;
}

.widget-header {
  margin: 1em 31px 1em 0.5em;
}

.user-role-wrapper .error-message {
  position: relative !important;
  width: 100% !important;
}

select {
  display: block;
  width: 50%;
  background: transparent;
  border-bottom: 1px dotted black;
}

.modal.show {
  display: block;
}

.modal-content-alert {
  display: flex;
  flex-direction: column;
  height: auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.profile-title {
  font-weight: 400;
  font-size: 34px;
}

.user-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.user-role {
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 114px;
  background: #070a0d;
  color: white;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 3px;
  width: 290px;
}

.user-role.selected {
  border: 3px solid #0a2a7a;
  box-sizing: border-box;
}

.user-role.disabled {
  opacity: 0.4;
}

.user-role.disabled:hover {
  cursor: no-drop;
}

@media screen and (max-width: 992px) {
  .layouts-grid-container {
    display: none;
  }

  .user-role {
    padding: 0.6em 1em;
  }
}

.roles-header {
  color: white;
  font-size: 1.3em;
  padding: 1em 0;
}

.user-role-wrapper {
  flex: 1;
  display: flex;
  font-size: 1em;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

@media screen and (max-width: 992px) {
  .user-role-wrapper {
    font-size: 0.65em;
    height: 100%;
  }
}

.select-role-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
}

.button-role {
  min-width: 120px;
  padding: 10px 20px;
  color: #d2d7e0;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.button-role:hover {
  opacity: 0.8;
}

.button-contained {
  background: #909fba96;
}

.button-active {
  background: #1142bc;
}

.button-disable {
  pointer-events: none;
}

.button-sign-out {
  background-color: #0b1116;
}

.sidebar-container {
  position: fixed;
  top: 0;
  padding-top: 4em;
  overflow: hidden;
  background: #0e161e;
  color: white;
  min-height: 100%;
  z-index: 888;
  max-width: 60px;
  transition: max-width 0.8s ease-out;
  overflow-y: auto;
  height: 100%;
}

@media screen and (min-width: 992px) {
  .sidebar-container:hover {
    max-width: 300px;
    transition: max-width 0.8s ease-in;
  }
}

@media screen and (max-width: 992px) {
  .sidebar-container {
    width: 100%;
    max-width: 100%;
  }
}

.sidebar-widget-icon {
  animation: show-down 1s;
}

@keyframes show-down {
  from {
    margin-top: -0.5em;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

.sidebar-container.hide {
  max-width: 0;
  transition: max-width 0.8s ease-out;
}

.sidebar-btn {
  min-width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 0.7em;
}

.sidebar-btn.active {
  color: #fecc36;
}

.sidebar-icon {
  padding: 1em 1.5em;
}

.nav-icon {
  margin: 0 0.5em;
}

.nav-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.84em;
}

@media screen and (max-width: 992px) {
  .nav-btn {
    margin-left: 0.5em;
  }
}

.nav-btn .fa {
  font-size: 0.5em;
}

.user-image {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.user-image-nav {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  float: right;
}

.current-time {
  padding-top: 4px;
  width: auto;
  display: flex;
}

.user-name {
  font-size: 20px;
  color: #cbcbcb;
}

.layout-type,
.user-grid-bar .selection {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8391e6;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* toggle btn */
.switch-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 15px;
  margin: 0 1em;
}

.switch input {
  display: none;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: auto !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  bottom: 3px;
  left: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.header-left-btns {
  display: flex;
}

.header-left-btns .list-btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 1.3em auto 0;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .header-left-btns .list-btn {
    width: 20px;
  }
}

.header-left-btns .list-btn .fa-bars {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .header-left-btns .list-btn.main-list:hover {
    background: grey;
  }
}

.nav-wrapper {
  padding: 0 1em;
}

.alerts-log-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #08080a99;
  z-index: 999999;
}

.alerts-log {
  background: #070b0f;
  border-radius: 5px;
  max-height: 100%;
  height: 900px;
  overflow: auto;
  width: 470px;
  padding: 0 1em;
  margin: auto;
  margin-right: 0;
}

.madal-alert-header {
  width: 339px;
  height: 29px;
  display: flex;
  justify-content: space-between;
}

.container-alerts-log {
  margin-top: 41px;
  margin-left: 40px;
  color: white;
}

.madal-alert-header .alerts-log-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.madal-alert-header .modal-close {
  width: 20px;
  display: flex;
  justify-content: center;
  font-size: 27px;
  cursor: pointer;
  color: white;
  align-items: center;
  margin-right: -32px;
}

.alert-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.2em;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}

.alert-level {
  padding: 2px 9px;
  border-radius: 6px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 23px;
  font-weight: 700;
  font-size: 12px;
  line-height: 26px;
  margin-right: 19px;
  margin-top: -17px;
}

.alerts-log::-webkit-scrollbar {
  width: 4px;
  height: 89px;
}

.alerts-log::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #909fba66;
}

.alert-level.high {
  background: #d12c00;
}

.alert-level.med {
  background: #d17100;
}

.alert-level.low {
  background: #edbd13;
}

.alert-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #909fba;
}

.border {
  border-top: 1px solid #313c48;
  margin-bottom: 20px;
  width: 380px;
}

.alert-body {
  margin-top: 3px;
}

.aircraft-reg {
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  margin-bottom: 5px;
}

.alert-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  margin-bottom: 0.3em;
}

.alerts-log-body {
  margin-top: 18px;
}

.alerts-log-body .main-alert {
  margin-bottom: 20px;
}

.input-field label {
  transition: all 0.3s;
  position: absolute;
  font-size: 0.7em;
}

.input-field input:focus + label,
.input-field input:not([value=""]) + label {
  top: -6px;
  font-size: 0.6em;
}

.widgets-wrapper .toastify {
  flex: 0;
}

.remove-widget-btn {
  position: absolute;
  z-index: 80;
  top: 0;
  right: 0;
  padding: 0.7em;
  font-size: 1.7em;
  cursor: pointer;
}

/* Override materialize style for table data padding */
td,
th {
  padding: 16px 3px !important;
}

input:-webkit-autofill:focus,
input:-webkit-autofill,
select:-webkit-autofill {
  transition: all 7000s ease-in-out 0s !important;
  background-image: none !important;
  color: rgb(255, 255, 255) !important;
}

.tabs {
  background-color: unset;
}

.user-roles-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 13px 22px;
  max-height: 370px;
  overflow-y: auto;
  padding-right: 30px;
}

@media screen and (max-width: 992px) {
  .user-roles-wrapper .user-role {
    min-width: unset !important;
  }
}

.asc-desc-wrapper {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.asc-desc-wrapper .radio-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.asc-desc-wrapper .radio-btn .record {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.3em 0 1.2em;
  font-size: 0.8em;
}

.asc-desc-wrapper .radio-btn .record .checkrecord.radio {
  width: 15;
  height: 15;
}

.emma-modal-alert {
  z-index: 99998;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.emma-modal {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.emma-modal.overlay {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
}

.notification-modal {
  z-index: 99999;
  position: fixed;
  top: 29px;
  left: 0px;
  right: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  width: 92%;
}

.notification-modal .notification-content {
  margin: auto;
  color: white;
  border-radius: 3px;
  padding: 1em 0;
  min-width: 25%;
  position: relative;
  animation: move_down 1s ease forwards;
  width: 561px;
  height: 40px;
}

.notification-modal .close {
  cursor: pointer;
  align-self: flex-end;
  font-size: 25px;
  position: absolute;
  top: 8px;
  right: 15px;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.close-icon {
  margin-right: 15px;
  font-size: 15px;
}

.notification-modal .notification-text {
  margin-left: 13px;
  width: 89%;
  display: flex;
}

.emma-modal .modal-content {
  margin: auto;
  color: white;
  background: black;
  border-radius: 3px;
  padding: 1em;
  min-width: 25%;
  position: relative;
  animation: move_down 1s ease forwards;
}

.emma-modal-alert.overlay {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.modal-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1em;
}

.close-alert-btn {
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  width: 130px;
  color: white;
  line-height: 15.96px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.modal-btns > div {
  padding: 1em 0.5em;
  cursor: pointer;
}

.modal-btns > div:hover {
  color: #fecc36;
}

.modal-btns .ok-btn {
  background: #0a2a7b;
  padding: 0.8em 3em;
  border-radius: 1px;
  margin: 0 0 0.5em 1em;
}

.emma-modal-alert .modal-content-alert {
  margin: auto;
  color: white;
  background: black;
  border-radius: 20px;
  padding: 1em;
  min-width: 25%;
  position: relative;
  animation: move_down 1s ease forwards;
}

.activity-info {
  padding: 4 rem 5.5 rem 3.75 rem 4.1 rem;
  flex-direction: column;
}

.font-action {
  font-size: 34px;
  line-height: 41px;
  letter-spacing: -0.7px;
  font-weight: bold;
}

.activity-info .activity-action {
  padding: 24px;
  font-size: x-large;
  display: block;
  text-align: center;
  width: 554px;
}

.activity-message {
  padding: 15px;
  margin: auto;
}

.card-activity-btn {
  display: block;
  text-align: center;
}

.container-btn {
  margin: auto;
}

.container-btns {
  display: inline-flex;
  width: fit-content;
  margin: auto;
}

.activity-btn {
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  width: 136px;
  height: 38px;
  background: #1142bc;
  color: white;
  line-height: 15.96px;
  margin: 15px;
  display: block;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .emma-modal-alert .modal-content-alert {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 992px) {
  .emma-modal .modal-content {
    font-size: 0.8em;
  }
}

@keyframes move_down {
  0% {
    transform: translate(0, -80px);
  }

  100% {
    transform: translate(0, 0px);
  }
}

.emma-modal .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin: auto auto 1em;
}

.emma-modal .warning-icon {
  color: #fecc36;
}

.emma-modal .info-icon {
  color: blue;
}

.emma-modal-alert .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin: auto auto 1em;
}

.emma-modal-alert .warning-icon {
  color: #fecc36;
}

.emma-modal-alert .info-icon {
  color: blue;
}

.modal-desc {
  align-self: center;
  font-size: 1.2em;
}

.modal-desc-alert {
  font-size: 16px;
  width: 530px;
  text-align: center;
  max-width: 530px;
  height: auto;
}

.emma-modal .close {
  cursor: pointer;
  align-self: flex-end;
  font-size: 2em;
  position: absolute;
  top: -15px;
  right: -15px;
  background: white;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.emma-modal .close:hover {
  color: #fecc36;
}

.emma-modal-alert .close {
  cursor: pointer;
  align-self: flex-end;
  font-size: 2em;
  position: absolute;
  top: -15px;
  right: -15px;
  background: white;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.emma-modal-alert .close:hover {
  color: #fecc36;
}

option {
  color: initial;
}

.indicator {
  background-color: #fecc36 !important;
}

.indicator.acdm-tabs {
  transition: all 0.5s ease-out;
  left: 0;
  right: 30%;
  width: 30%;
}

.tabs {
  overflow: hidden;
}

.tabs .tab {
  color: #939191;
  font-weight: 700;
  text-transform: capitalize;
}

.tabs .tab.active {
  color: white;
}

.list-btn.play-btn {
  width: unset;
  font-size: 0.8em;
}

.list-btn.play-btn:hover {
  background-color: unset;
}

.list-btn.active {
  color: #fecc36;
}

table th {
  position: sticky;
  top: 0;
  background: var(--background-color);
  z-index: 777;
}

.current-time .time {
  font-weight: 700;
  min-width: 69px;
}

.time-utc {
  margin-left: 6px;
  width: max-content;
  color: #8a93a8;
}

.input-field input:focus + label {
  top: -22px;
}

.input-field .to-top {
  margin-top: -15px;
}

.input-field select option {
  background-color: #002b36;
}

.colors-boxes {
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: row;
  padding: 1em;
  background: #060609a8;
  border-radius: 5px;
  justify-content: space-around;
  padding: 2px;
  font-size: 12px;
}

.color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.color-status {
  padding: 0 0.4em 0 0.4em;
  font-size: 1em;
  color: #cbcbcb;
}

.box.first-frame {
  display: none;
}

.datepicker-input-wrapper {
  display: block;
}

.datepicker-input-wrapper:not(.sys_params_dp) {
  position: relative;
}

.sidemenu,
.navbar {
  display: none !important;
}

input[type="file"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
select {
  direction: ltr;
  text-align: left;
  width: 100%;
  padding: 12px;
  border: none;
  border-bottom: 1px solid #212831;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  min-height: 3em;
  box-shadow: none;
}

input[type="submit"] {
  background-color: #0a2a7b;
  color: white;
  font-size: 14px;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 50%;
  align-self: center;
  margin: 1em;
}

input[type="submit"]:hover {
  background-color: rgb(224, 229, 240);
}

.filter-by {
  margin: 2em;
}

.dashboard-filter.filter-by {
  margin: unset;
  margin-right: 2em;
  margin-top: 5%;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-close {
  font-weight: bolder;
  font-size: 2em;
  cursor: pointer;
  position: relative;
  left: 0.2em;
}

.dashboard-filter.filter-close {
  bottom: 1.5rem;
}

.dashboard-filter.filter-header {
  margin-bottom: 2rem;
}

.filter-items-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -1em;
  width: max-content;
}

.dashboard-filter.filter-items-wrapper {
  margin-left: unset;
}

.activity-info .container-rate {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* The record */
.record {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 100%;
}

.acdm-record {
  margin-right: 13px;
}

.filter-record {
  margin-bottom: 18px;
  padding: 1rem;
}

/* Hide the browser's default checkbox */
.record input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkrecord {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1.2px solid #9696ab;
  border-radius: 3px;
}

.checkrecord.disabled,
.checkrecord.disabled:hover,
.record:hover input ~ .checkrecord.disabled {
  background: grey;
  pointer-events: none;
  cursor: auto !important;
}

.record:hover input ~ .checkrecord {
  background-color: #ccc;
}

.record input:checked ~ .checkrecord {
  background-color: #2196f3;
  cursor: pointer;
}

.checkrecord:after {
  content: "";
  position: absolute;
  display: none;
}

.record input:checked ~ .checkrecord:after {
  display: block;
}

.record .checkrecord:not(.radio):after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.indicator.airlines-tabs {
  transition: all 0.5s ease-out;
  right: 30%;
  width: 50%;
}

.indicator.airlines-tabs.left {
  left: 0;
}

.indicator.airlines-tabs.right {
  left: 50%;
}

.icon-text {
  font-size: 12px;
  margin-left: 4px;
  width: 79%;
}

.list {
  display: flex;
  flex: 1;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .filter-font-size {
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  .table-font-size {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .table-font-size {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .table-font-size {
    font-size: 12px;
  }

  .filter-font-size {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .table-font-size {
    font-size: 0.6em;
  }
}

.filter-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-items-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -1em;
  width: max-content;
}

.filter-input {
  min-height: 0 !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent;
}

.filter-item .item-name {
  transition: all 0.5s;
}

.filter-item .item-name.input-mode {
  transform: translate(0, -12px);
  transition: all 0.5s;
  color: grey;
  font-size: 0.9em;
  height: 8px;
  padding: 8px;
}

input[type="file"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
select {
  direction: ltr;
  text-align: left;
  border: none;
  box-sizing: border-box;
  resize: vertical;
  box-shadow: none;
}

input[type="text"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]) {
  border: none;
  box-shadow: none;
}

input[type="text"]:disabled {
  -webkit-text-fill-color: #72787f;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.uiInput {
  margin: 0 !important;
  width: 100%;
}

.uiInput > div > input {
  padding-top: 21px;
  border-bottom: none !important;
  margin: 0;
  padding-bottom: 0;
  color: white;
}

.uiInput > p {
  color: #d32f2f;
  position: absolute;
  top: 48px;
}

.uiInput > label {
  top: -4px;
  color: white;
}

.uiInput {
  color: white;
}

.uiInput::before {
  border-bottom: none;
}

.container {
  background-color: #202831;
  width: 100%;
  border: 1px solid #2a3d52;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.inputIcon {
  display: flex;
  justify-content: center;
  width: 10%;
  height: 100%;
  align-items: center;
}

.ems-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.map {
  height: 100%;
  width: 100%;
}
.chart-wrapper {
  height: 80%;
  width: 100%;
  display: none;
  flex-direction: column;
}

.ems-close-btn {
  cursor: pointer;
  align-self: flex-end;
  font-size: 2em;
  width: 1em;
  height: 1em;
  display: flex;
  color: white;
}

.ems-filter {
  position: absolute;
  top: 26%;
  left: 1%;
  background: #0e161e;
  border-radius: 9px;
  padding: 0em 1.2em;
}

.name-date {
  display: flex;
  justify-content: space-between;
  padding: 0px 2.5em;
}

.ems-name {
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

.chart-variables {
  display: flex;
  height: 100%;
  padding: 0 2.5em;
  justify-content: space-around;
}

.variables {
  width: 12em;
}

.variables > div {
  border: 1px solid #cccccc;
  border-radius: 25px;
  padding: 0.6em 2em;
  margin: 0.9em;
  cursor: pointer;
  text-align: center;
}

.filter {
  padding: 0.6em 0em;
  border-radius: 25px;
  min-width: 11em;
  margin: 0.9em;
  border: 1px solid #343434;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
.hidden {
  visibility: hidden;
}
.air-filter-wrapper {
  border-radius: 12px;
}
.air-filter-wrapper.active {
  background: #0a2a7b;
}
.noise-filter {
  background: #0a2a7b;
}
.air-filter-drop {
  padding: 0em 2em;
}
.active-variable5 {
  background: #0a2a7b;
}

.chart {
  height: 100%;
  width: 80%;
  background: #0e161e;
  border-radius: 20px;
  visibility: hidden;
}

.marker img {
  width: 70.09px;
  height: 70.09px;
}

.variables.small {
  display: flex;
}

.chart-variables.small {
  flex-direction: column;
  padding: 0 5.5em;
}
.variables.small > div {
  margin: 0.3em;
  margin-bottom: 0.9em;
}

.chart-wrapper.small {
  height: 93%;
}

/* Overwrite zoom controle */
.gmnoprint div {
  border-radius: 12px !important;
  background: var(--main-color) !important;
}
button.gm-control-active img {
  height: 12px !important;
  width: 12px;
}

.gmnoprint div > :first-child div {
  border-bottom: 1px solid #c4c8d0 !important;
  width: 60% !important;
  margin-left: 20% !important;
}

button.gm-control-active {
  background-color: white !important;
}
.gmnoprint button:first-child {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.gmnoprint button:last-child {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.rlc-date-time-selects {
  padding: 0 4px;
}

.modal-content-popup {
  margin: auto;
  color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 390px !important;
  height: auto;
  background-color: #000;
  z-index: 999;
}

.polygon {
  width: 19.8px;
  height: 19.8px;
  background-color: #000;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

  margin-left: 290px;
}

.modal-header {
  margin-top: 25px;
  margin-left: 24px;
  width: 339px;
  height: 29px;
  display: flex;
  justify-content: space-between;
}

.modal-header .modal-header-main {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.modal-header .modal-close {
  width: 20px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.container-tab {
  margin-left: 24px;
  margin-top: 15px;
  height: 32px;
  display: flex;
  align-items: center;
}

.item-tab {
  display: flex;
  text-transform: uppercase;
  width: 41px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #909fba;
  margin-right: 24px;
  justify-content: center;
  cursor: pointer;
}

.item-tab-active {
  width: 67px;
  height: auto;
  color: white;
  background: #141e29;
  border-radius: 90px;
  display: flex;
  justify-content: center;
  padding: 3px 15px;
  font-weight: 600;
}

.container-date {
  height: auto;
  margin-top: 24px;
  margin-left: 31px;
  width: 83%;
  margin-bottom: 18px;
}

.time-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #fff !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.btn-submit,
.btn-submit:link,
.btn-submit:focus,
.btn-submit:visited {
  display: inline-block;
  width: 324px;
  height: 48px;
  background-color: #1142bc;
  border-radius: 8px;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 600;
  cursor: pointer;
  padding: 14px;
  margin-top: 13px;
  text-decoration: none;
  color: white;
  margin-bottom: 40px;
}

.text-btn {
  display: flex;
  justify-content: center;
}

.error-text {
  color: #c32638;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.container-btn {
  display: flex;
  justify-content: center;
}

.form-input {
  width: 311px !important;
  display: block;
  margin-left: 6px !important;
}

[data-disabled-btn="true"] {
  color: #909fba;
  background-color: #1142bc40;
  pointer-events: none;
}

.clock-icon {
  position: absolute;
  left: 20px;
  top: 16px;
  z-index: 9;
  font-size: 21px;
}

.date-icon {
  position: absolute;
  left: 22px;
  top: 14px;
  z-index: 9;
  font-size: 21px;
}

.container-time {
  width: 324px;
  padding: 2px 0px 3px 6px;
  background: rgb(32, 40, 49);
  border-radius: 10px;
  height: 50px;
  margin-bottom: 14px;
  position: relative;
  border: 1px solid #2a3d52;
}

.time-label {
  position: absolute;
  margin-left: 37px;
  color: #909fba;
  display: inline-block;
  font-size: 13px;
  margin-top: 2px;
}

.error-label {
  position: relative;
  left: 47px;
  font-size: 16px;
  top: 17px;
  color: #202831;
}

.acdm-manual-update-number {
  width: 324px;
}

.date-filter-wrapper {
  display: flex;
  flex-direction: row;
  background-color: #202831;
  border: 1px solid #2a3d52;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  color: white;
}

[data-disabled="true"] {
  color: #202831;
  background-color: #0f1317;
}

.stand {
  fill: none;
  cursor: pointer;
}
.circle,
.airport-pan-row circle:not(.blocked-area-corner) {
  fill: red;
}

.aircraft-icon {
  transform: translate(-20px, -12px);
}

@media screen and (min-width: 992px) {
  .weather-container .tabs .tab {
    font-size: 1vw;
  }
}

.track {
  fill: none;
  stroke: none;
}

div.tooltip {
  text-align: left;
  font-size: 1em;
  border-radius: 3px;
}

.close-active-flight-btn {
  position: absolute;
  right: 0;
  padding: 1em;
  font-size: 2em;
  width: 25;
  height: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.marker {
  cursor: pointer;
}

.label {
  color: #b2b4b4;
}

.airplane {
  padding: 0 !important;
  flex: 1;
  min-height: 0;
  display: flex;
  height: 150px;
  font-size: 0.65em;
  transition: width 0.5s;
}

.airplane.active {
  position: absolute;
  width: 100%;
  z-index: 7777;
}

hr {
  width: 100%;
}

.emma-modal .warning {
  color: #b30b0b;
  font-size: 0.9em;
}

hr.edit-strash-splitter {
  margin: 0 0 5px 0;
}

.trash-edit-container i {
  padding: 0 5px;
  font-size: 1.2em;
}

.trash-edit-container i:hover {
  color: #006fff;
}

span.label {
  font-size: 12px;
}

span.margin {
  display: inline-block;
}

.asmgcs-container {
  display: flex !important;
  flex-direction: column;
}

.svgContainer {
  position: relative;
  background-color: #333;
  display: flex;
  justify-content: center;
  flex: 1;
  min-height: 0;
}

.airplanes-panel-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  /* width: 100%; */
}

.inner svg {
  fill: #fff;
}

g.aircraft {
  cursor: pointer;
  transform-box: fill-box;
  transform-origin: 80% 50%;
  -ms-transform-origin: 0px 0px;
}

g.vehicle {
  cursor: pointer;
  transform-box: fill-box;
  transform-origin: 80% 50%;
  -ms-transform-origin: 0px 0px;
}

.vehicle-icon {
  transform: translate(-20px, -12px);
  -moz-transform: translate(-9px, -9px);
}

@supports (-ms-ime-align: auto) {
  g.vehicle {
    transform-origin: 0px 0px;
  }
  .aircraft-icon {
    transform: translate(-20px, -6px);
  }
  .vehicle-icon {
    transform: translate(-9px, -9px);
  }
}

_:-ms-lang(x),
g.aircraft {
  transform-origin: 0 0;
}

.active.aircraft {
  fill: #f7b100;
}

.row .col.s12.airplane-details {
  background: #fff;
  position: absolute;
  width: 100%;
  right: -100%;
  transition: 0.5s;
}

.airplanes-panel-container .row {
  display: flex;
  flex: 1;
}

.airplanes-panel-container .row .flights {
  display: flex;
  flex: 1;
  width: 100%;
  color: black;
}

.airplanes-panel-container .row .flights .flights-list {
  display: flex;
  flex: 1;
}

.airplanes-panel-container .row .col.s12.airplane-details.active {
  transition: 0.5s;
  right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 100;
  line-height: 1.5em;
}

@media (max-width: 64em) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
  }
}

h1 {
  font-size: 5.0625em;
}

h2 {
  font-size: 3.375em;
}

h3 {
  font-size: 2.56em;
}

h4 {
  font-size: 1.5em;
  font-weight: normal;
}

h5 {
  font-size: 1.61813em;
}

h6 {
  font-size: 1em;
  font-weight: normal;
}

.float-label,
.input-column .input-column-header,
.maintenance-track-label,
.global-save-cancel > button,
.sign-in-btn,
.modal-footer button,
.local-time-label,
[class*="flights-list"] .flight-info-label,
.tool-tip-title,
.tool-tip-message,
.atcw-section-title,
.widget-selector-item-label {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.3em;
}

[class*="flights-list"] .flight {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.333%;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  background: white;
  border: 1px solid #dddddd;
  padding: 0.5em;
  cursor: pointer;
  cursor: hand;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

[class*="flights-list"] .flight:hover {
  background: #f7f7f7;
}

[class*="flights-list"] .flight-carrier {
  text-align: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

[class*="flights-list"] .flight-carrier img {
  max-height: 2.5em;
  max-width: 12.5em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

[class*="flights-list"] .flight-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 0.5em;
  height: 100%;
}

[class*="flights-list"] .flight-info-section {
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0.5em 1em;
}

.tooltip .flight-info-label {
  color: #9c9696;
}

[class*="flights-list"] .flight-info-label {
  font-size: 0.85em;
}

[class*="flights-list"] .flight-info-unit {
  white-space: nowrap;
}


button,
button:focus {
  border: none;
  background: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: hand;
}

.plane .tool-tip-title {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  padding-left: 0.5em;
  font-size: 0.875em;
  color: #282828;
}

.plane .tool-tip-image {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
}

.plane .tool-tip {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.tool-tip-image {
  max-width: 6.875em;
  max-height: 1.875em;
}

.flights-list {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  overflow: auto;
}

.flights-list::-webkit-scrollbar {
  display: none;
}

.plane-info-splitter {
  background: #b1b3b3;
  width: 1px;
  height: 3em;
  align-self: center;
}

.flight-info .flight-info-section h2.flight-info-unit {
  font-size: 1.3em !important;
}

svg.block-area {
  border: solid 1px red;
}

rect.draggable-blocked-area {
  fill: rgb(241, 95, 95);
  stroke: rgb(201, 12, 12);
  stroke-width: 1.5px;
  opacity: 0.2;
}

.saveRect {
  background: rgb(179, 179, 233);
  color: black;
  z-index: 10000;
  position: absolute;
  padding: 10px;
}

.blocked-area-note.close-btn {
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  font-size: 0.7em;
  right: -8px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  border: 1px solid white;
  background: black;
  color: white;
  padding: 2px 5px;
}

.blocked-area-note.close-btn:hover {
  color: black;
  background: white;
  border: 1px solid black;
}

.airport_blocked_area {
  opacity: 0.2;
}

.blocked-area-note.wrapper {
  width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: white;
  border-radius: 4px;
  padding: 0.3em;
  font-size: 0.9vw;
}

.blocked-area-note.wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: 12px;
  left: -10px;
  z-index: 99;
  transform: rotate(270deg);
}

.blocked-area-note.wrapper-content {
  line-height: normal !important;
}

.block-btn {
  position: absolute;
  z-index: 100;
  height: 1.9em;
  width: 1.9em;
  border-radius: 3px;
  font-size: 2em;
  right: 60px;
  top: 12px;
}

.asmgcs-container .block-btn {
  font-size: 1em;
  background: #060a0d;
  display: flex;
  justify-content: center;
  align-content: center;
}

.asmgcs-container .block-btn .fa {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-btn.deactive {
  pointer-events: none;
  color: grey;
}

.blocked-area-note.text {
  fill: black;
  font-size: 1em;
  font-weight: bold;
}

.blocked-area-note.delete-btn,
.blocked-area-note.edit-btn {
  font-size: 1.5vw;
  font-weight: bold;
  fill: white;
  cursor: pointer;
}

.blocked-area-text-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emma-modal.blocked-area-text-wrapper label {
  font-size: 0.7em;
  padding: 1em 0 0 0;
}

.emma-modal.blocked-area-text-wrapper .blocked-area-date-picker {
  margin: 1px;
  color: white;
}

.emma-modal.blocked-area-text-wrapper .blocked-area-end-date {
  margin-top: 1em;
}


.tooltip.blocked-area-tooltip {
  position: fixed !important;
  background: white;
  color: black;
  width: 220px !important;
}
.tooltip.blocked-area-tooltip-content {
  width: 220px !important;
}
.tooltip.airplane-tooltip {
  position: fixed !important;
  color: white !important;
  margin-top: 15px !important;
  background-color: #070b0f !important;
  width: 250px !important;
  height: 6rem !important;
  border-radius: 8px !important;
}

@-moz-document url-prefix() {
  .tooltip.airplane-tooltip {
    margin-left: 30px !important;
  }
}

@media screen and (max-width: 992px) {
  .tooltip.airplane-tooltip {
    width: 200px !important;
  }
}

.tooltip .flight-carrier-logo {
  padding: 0 0.5em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.tooltip.airplane-tooltip-content {
  height: 100% !important;
  padding: 3px 0px 3px 9px !important;
  width: 100% !important;
}

.header-content {
  font-size: 10px;
  color: #909fba;
}

.header-flight {
  font-size: 10px;
  line-height: 1;
}

.flight-tooltip-logo {
  background-color: white;
  width: 50px;
  height: 27px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.header-tooltip {
  display: flex;
    justify-content: space-between;
    width: 98%;
    margin-top: 2px;
}

.mt-field {
  width: 90%;
  height: 31%;
}

.header-cell {
  width:66px ;
}

.header-field-cell {
  width:50px;
}

.upper-case{
  text-transform: uppercase;
}
.tooltip.tooltip.airplane-tooltip .airplane-tooltip-arrow span {
  border-color: #070b0f transparent transparent !important;
}

.tooltip.airplane-tooltip .flight-tail,
.tooltip.airplane-tooltip .flight-number {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
  height: 100%;
}

.airplane-tooltip-info {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.airplane-tooltip-tail-logo {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex: 1 !important;
  align-items: center;
}

@keyframes fromLeft {
  from {
    opacity: 0;
    x: -100;
  }
  to {
    opacity: 0.9;
    x: 50;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.9;
  }
}

label.block-red:before {
  border: 2px solid red !important;
}

label.block-red:after {
  background: red !important;
  border: 2px solid white !important;
}

label.block-yellow:before {
  border: 2px solid yellow !important;
}

label.block-yellow:after {
  background: yellow !important;
  border: 2px solid white !important;
}

label.block-green:before {
  border: 2px solid green !important;
}

label.block-green:after {
  background: green !important;
  border: 2px solid white !important;
}


.react-datepicker-wrapper input[type="text"] {
  font-size: 0.8em;
}

.react-datepicker-wrapper {
  width: 100%;
}

label[for="blocked-area-start-date"],
label[for="blocked-area-end-date"] {
  font-size: 0.7em;
}


input[type="text"]#blocked-area-end-date,
input[type="text"]#blocked-area-start-date {
  height: auto;
  margin: 5px;
}

.btn-group-vertical {
  flex-direction: column;
  position: absolute;
  z-index: 111;
  right: 0;
  bottom: 0;
  padding: 5px;
  display: flex;
  max-height: 70%;
}

.zoom-controller-btns {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
}

.zoom-controller-btns div {
  display: block;
  float: none;
  line-height: 0;
  box-shadow: none;
  width: 1.7em;
  height: 1.7em;
  -webkit-box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #060a0d;
  color: white;
  border-radius: 3px;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn {
  display: inline-block;
  padding: 3px 5px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
}

#reset {
  margin-bottom: 5px;
}

.btn:hover,
.btn-large:hover {
  background-color: white;
}

.btn:focus,
.btn-large:focus,
.btn-floating:focus {
  background-color: white;
}

.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  float: left;
}

#map {
  background: skyblue;
  width: 965px;
  height: 505px;
  border: 3px solid steelblue;
}

#map-zoomer {
  position: absolute;
  writing-mode: bt-lr;
  -webkit-appearance: slider-vertical;
  padding: 0 5px;
  position: absolute;
  top: 110px;
  left: 22px;
}

.zoom-range {
  height: 72px;
}

#airport-pan {
  height: 100% !important;
  max-width: 100%;
  width: 100%;
  /* To fix animation issus on chrome */
  position: absolute;
}

.flight-details {
  z-index: 999;
}
@media screen and (max-width: 992px) {
  .asmgcs-container {
    font-size: 0.9em;
  }

  .flights-details.active {
    font-size: 0.8em;
  }

}

.airport-pan-contianer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100;
  max-height: 100%;
  width: 100%;
}

.airport-pan-row.airport-pan-row-1 {
  display: flex;
  flex: 1 2;
  height: 100%;
  max-height: 100%;
  min-height: 0;
}

.airport-pan-row.airport-pan-row-2 {
  display: flex;
  background-color: #eaeaea;
}

.flights-details-toggle-btn {
  position: absolute;
  left: 0;
  padding: 1em;
  z-index: 1;
}

.close-asmgcs-tooltip {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 2em;
  height: 2em;
  background: #070b0f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid black;
  font-size: 0.7em;
}

.tooltip {
  width: 350px !important;
}

.tooltip.stand-tooltip {
  position: fixed !important;
  color: #cbcbcb !important;
  background-color: #070b0f !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.stand-tooltip-info {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0px;
}

.tooltip .stand-tooltip-content {
  padding: 0px 5px !important;
}

.noise-tooltip {
  position: fixed;
  color: #cbcbcb;
  background-color: #070b0f;
  display: flex;
}

.noise-info {
  width: 26rem;
  height: 3rem;
  border-radius: 8.83px;
  padding: 7px 7px;
  z-index: 1;
}

.col-noise {
  width: 7rem;
}

.total-active-flight {
  font-size: 13px;
  font-weight: 400;
}

.active-flight {
  font-size: 12px;
  font-weight: 400;
}

.header-col {
  font-size: 10px;
  color: #909fba;
}

.noise-value {
  font-size: 12px;
  margin-top: 5px;
}

.stand-data {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.1em;
  padding: 3px 2px;
}

.block-tooltip{
  visibility: hidden;
}

.stand:hover + rect {
  visibility: visible;
}

.stand:hover + rect + text {
  visibility: visible;
}

.tooltip .stand-info-label {
  color: #8d8d8d;
  margin: 3px;
  padding: 5px 0px;
}

.stand-info-unit {
  line-height: 1;
  white-space: nowrap;
  font-size: 1.1em;
  margin: 3px;
  color: #cbcbcb;
}

.status-color {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 5px;
  border-radius: 2px;
}

.stand-data.stand-status,
.stand-data.stand-reg {
  flex-basis: 148px;
  font-size: 1.1em;
}

.stand-data.stand-iata,
.stand-data.stand-name {
  flex-basis: 100px;
}

.stand-data.stand-type,
.stand-data.stand-time {
  flex-basis: 100px;
}

.tooltip.stand-tooltip-arrow span {
  border-color: #070b0f transparent transparent !important;
}

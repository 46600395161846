.headerFilter {
    display: flex;
    align-items: center;
  }

  .filterCancelButton{
    border-radius: 50%;
    height: 2em;
    width: 5px;
    padding: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
  }

  .filterCancelButton:hover {
    background-color: #2A3D52;
    font-weight: bolder;
  }

  .timePickerFlex {
    display: flex;
    flex-direction: row;
    background-color: #202831;
    border: 1px solid #2a3d52;
    border-radius: 8px;
    height: 48px;
    width: 340px;
  }

  .timeBoxFlex {
    display: flex;
    flex-direction: row;
    gap: 23px;
  }

  .timeHidenSelectedFlights {
    position: absolute;
    opacity: 0;
    z-index: 2;
  }

  .dateFilterWrapper {
    display: flex;
    flex-direction: row;
    background-color: #202831;
    border: 1px solid #2a3d52;
    border-radius: 8px;
    height: 48px;
    width: 340px;
    color: white;
  }


  .popover-test > div >div  {
    background-color: red;
    color: white;
    border-radius: 12px,
  }

  .boxModal{
    background-color: #070B0F;
    width: 382px;
    height: 280px;
    padding: 20px;
    border-radius: 1.4 px;
    max-height: 80vh;
    overflow-Y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-X: hidden
  }

  .timeBoxFlex > svg {
    position: relative;
    top: 12px; 
    left: 10px;
    color: white;
  }

  .timeInput >label {
    color: #909FBA;
    top: 4px;
  }

  .timeInput >div > input{
   color: white;
  }

  .headerWrapper{
    display: flex;
    justify-content: space-between;
  }

  .headerText{
    font-size: 24px;
    font-weight: 400;
    color: white;
    margin-bottom: -10px;
  }
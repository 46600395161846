  .timePickerFlex {
    display: flex;
    flex-direction: row;
    border: 1px solid #2a3d52;
    border-radius: 8px;
    height: 48px;
    width: 100%;
  }

  .darkColor{
    background-color: #202831;
  }

  .timeBoxFlex {
    display: flex;
    flex-direction: row;
    gap: 23px;
  }

  .timeHidenSelectedFlights {
    position: absolute;
    opacity: 0;
    z-index: 2;
  }

  .dateFilterWrapper {
    display: flex;
    flex-direction: row;
    background-color: #202831;
    border: 1px solid #2a3d52;
    border-radius: 8px;
    height: 48px;
    width: 340px;
    color: white;
  }

  .boxModal{
    background-color: #070B0F;
    width: 382px;
    height: 260px;
    padding: 20px;
    border-radius: 1.4 px;
    max-height: 80vh;
    overflow-Y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-X: hidden
  }

  .timeBoxFlex > svg {
    position: relative;
    top: 12px; 
    left: 10px;
    color: white;
  }

  .darkColorSvg{
    color: #4d5767 !important;
  }

  .timeInput > label {
    color: #909FBA;

  }

  .timeInputShrink > label {
    color: white;
    top: -5px;
  }

  .timeInput >div > input{
   color: white;
  }

  .timeInput > div>input:disabled{
    -webkit-text-fill-color: #4d5767;
  }

  .timeErrorMsg {
    position: absolute;
    font-size: 12px;
    color: #c32638;
  }

  .wrapperTime{
    position: relative;
  }
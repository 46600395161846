.row {
    max-width: 100%;
    margin: 0 auto; 
    background-color:#0e161d ;
    color: white;
}
    .row::after {
      content: "";
      display: table;
      clear: both; }
    .row [class^="col-"] {
      float: left; }
      .row [class^="col-"]:not(:last-child) {
        margin-right: 1rem; }
    .row .col-1-of-2 {
      width: calc((100% - 1rem) / 2); 
    }

.image {
      background-image: linear-gradient(to left top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%),url('/public/assets/images/seo_login.png');
      background-size: cover;
      height: 100vh;
      background-position: center;
      position: relative;
}

.header-text-box{
    position: absolute;
    left: 62px;
    bottom: 33%;
    width: 400px;
}
.header-text-box ul li {
    list-style-type: disc;
    margin-bottom: 6px;
    margin-left: 12px
}

.list-header{
    position: absolute;
    display: block;
    width: 500px;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    margin-top: 30px
}

.header-text-box .heading-primary-main{
    font-size: 48px;
    font-weight: 400;
    line-height: 1.2
}

.logo{
  position: absolute;
  left: 62px;
  bottom: 50%;
}

.logo img {
  width: 128px;
}

.header-login{
  height: 92vh;
  position: relative;
}
.header-box{
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-box .emma-logo{
  width: 115px;
  height: 24px;
}

.login-label{
  align-items: center;
  display: flex;
  font-size: 34px;
  font-weight: 400;
  line-height: 1.1;
  margin-top: 7px;
  height: 65px;
  margin-bottom: 34px;
}

.submit-login{
  border-radius: 6px!important;
  width: 380px;
  background: #205CF6;
  height: 49px;
  cursor: pointer;
}

.submit-login-text{
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  color: white;
  justify-content: center;
  display: flex;
  padding: 10px 0 16px 0;
  height: 100%;
}

@media screen and (max-width: 1142px) {
  .list-header {
    width: 450px;
  }
  .logo img {
    width: 100px;
  }
  .header-text-box .heading-primary-main{
    font-size: 30px;
  }
  .header-text-box{
    bottom: 40%;
  }
}

@media screen and (max-width: 992px) {
  .list-header {
    width: 300px;
  }
  .logo img {
    width: 90px;
  }
}

@media screen and (max-width: 800px) {
  .list-header {
    width: 270px;
    margin-top: 10px;
  }
  .logo img {
    width: 50px;
    margin-bottom: 29px;
  }
  .header-text-box .heading-primary-main{
    font-size: 18px;
  }
  .header-text-box{
    bottom: 46%;
    width: 70%;
  }
  .submit-login{
    width: 90%;
  }
  .login-label{
    font-size: 30px;
  }
}

.button_container {
    gap: 17px;

}



@media screen and (max-width: 1200px) {
    .widget-icon .fa {
        width: 45px;
        height: 45px;
    }

    .widget-icon-container {
        width: 45px;
        height: 45px;
    }
}
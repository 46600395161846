.error-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 45px;
  margin-top: 30px;
}
.restriction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.restricte-msg {
  font-size: 28px;
  font-weight: 500;
  margin: 0;
}
.error-description {
  color: #909fba;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

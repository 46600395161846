.bhs-belt-info-flight-wrapper {
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0.3em;
  border-top: 1px solid grey;
}


hr {
  height: 1px;
  border: none;
  margin: 0;
  background: lightgrey;
}

tbody tr.belt-info {
  box-shadow: 0 1px 2px rgba(211, 193, 193, 0.15);
  transition: all 0.3s ease-in-out;
}

.active-belt {
  border: 1px solid green;
  transition: opacity 0.3s ease-in-out;
}

div.bhs-tooltip-text {
  text-align: left;
}

.bhs-tooltip {
  position: fixed !important;
}

.under-maint {
  color: #d62424;
}

.dashoffset {
  animation: strokeDashoffset 70s linear infinite;
}

g:not(.belt) path.node {
  stroke-width: 0.4px;
}


@keyframes strokeDashoffset {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 100%;
  }
}

.bhs-svg .belt path {
  stroke-dasharray: 0;
}

.bhs-container {
  background: #1a1b1d;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bhs-svg .dark-pathes path {
  fill: rgb(100, 100, 100);
  stroke: rgb(115, 102, 102);
}

.bhs-svg {
  width: 100%;
  height: 100%;
  /* To fix animation issus on chrome */
  position: absolute;
}

.bhs-svg-container {
  display: flex;
  flex: 1;
  min-height: 0;
}
.bhs-header {
  min-height: 2.5rem;
  padding-left: 22px;
}

.info-belt{
  background: none;
  border-bottom: 1px solid rgb(34, 47, 58);
  height: 44px;
}

.bhs-header div.bhs-checkbox-wrapper {
  margin: 0 !important;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .bhs-container {
    font-size: 0.9em;
  }
}

.bhs-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.belt-flights-details,
.belt-flight-number {
  display: flex;
  justify-content: space-between;
}

.belt-flights-details > * {
  flex: 1;
}

.bhs-table {
  width: 100%;
}

.belt-info th {
  background: #1a1b1d !important;  
  display: table-cell;
  text-align: left;
}


 .bhs-cell{
    border-bottom: 1px solid rgb(84, 95, 115);
    height: 44px;
    color: rgb(144, 159, 186);
}

.bhs-table-wrapper {
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  margin-left: 22px;
  margin-top: 23px;
}

.bhs-belt-info-flight-wrapper.active-flight {
  color: green;
  font-weight: bold;
}

.animate-belt {
  transform: translate3d(0px, 0px, 0px);

  animation: move 10s ease infinite;
}

@keyframes move {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  50% {
    transform: translate3d(77px, 0px, 0px);
  }
}

.activity-table-wrapper {
  display: inline-block;
  min-height: 0;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.activity-table-wrapper .acdm-table {
  position: relative;
}

.cell-table {
  color: white;
  border-bottom: none;
  text-align: left;
}

.color-tranparent{
  color: transparent;
}

.widget-table-activity tr:nth-child(even),
.widget-table-activity-size tr:nth-child(even) {
  background-color: #202831;
}

.widget-table-activity tr:nth-child(odd),
.widget-table-activity-size tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

.widget-table-activity tbody tr td {
  font-size: 16px;
  text-align: start;
  word-wrap: break-word;
  border-radius: 0;
}
.widget-table-activity-size tbody tr td {
  font-size: 14px;
  text-align: start;
  word-wrap: break-word;
  border-radius: 0;
}

.widget-table-activity tbody tr td.radio-btn,
.widget-table-activity-size tbody tr td.radio-btn {
  display: flex;
  justify-content: center;
}

.widget-table-activity th {
  font-size: 16px;
}
.widget-table-activity-size th {
  font-size: 14px;
}

.widget-table-activity th,
.widget-table-activity-size th {
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #cbcbcb;
  background: #202831;
}

.widget-table-activity tbody tr td {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}

.widget-table-activity-size tbody tr td {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}

.tables-wrapper .widget-table-activity,
.tables-wrapper .widget-table-activity-size {
  overflow: auto;
  color: white;
}

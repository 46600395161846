.settings-flex-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  margin: 20px auto;
}

.main-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
  height: auto;
  margin-top: 37px;
}

@media screen and (max-width: 992px) {
  .search-input {
    left: -16em;
    right: 17%;
  }
}

.cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.search-card {
  width: 35%;
  height: 46px;
  background-color: #202831;
  margin-top: 1.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  border-radius: 12px;
  color: gray;
}

.search-input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 3;
}

@media screen and (max-width: 920px) {
  .search-card {
    width: 95%;
    height: auto !important;
    min-height: 40;
  }
}

.search-card input[type="text"],
.search-card input[type="text"]:focus {
  border: none;
  margin: 0;
  box-shadow: none;
  background-color: transparent;
}

.card {
  display: flex;
  align-items: center;
  padding: 1.5em;
  min-height: 9em;
  border-radius: 12px;
  cursor: pointer;
  background: #202831;
}

.card:hover {
  opacity: 0.8;
}

.card-icon {
  font-size: 42px;
}

.card-main {
  display: flex;
  flex-direction: column;
  margin-left: 1.5em;
  align-items: flex-start;
}

.card-header {
  font-size: 1.5em;
  display: flex;
  flex-direction: row-reverse;
}

.card-header .card-icon {
  padding: 0 0.5em;
}

.card-body {
  flex: 1;
  direction: ltr;
}

.card-plus-icon {
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  background: rgb(0 0 0 / 15%);
}

select option {
  background: #e9edf0;
  color: white;
}

.airline-img-upload-label {
  color: lightgrey;
}

.form-header {
  width: 100%;
  text-align: left;
  font-size: 1.5em;
  font-weight: bold;
  color: #d3d3d3;
  align-self: flex-start;
  text-transform: capitalize;
}

.crud-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem 0 7rem;
}

/* forms css */
input[type="file"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
select {
  direction: ltr;
  text-align: left;
  width: 100%;
  padding: 12px;
  border: none;
  border-bottom: 1px solid #b5bdce;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  min-height: 3em;
  color: white;
  box-shadow: none;
}

.input-field > input:disabled {
  border-bottom: 1px solid #ccc;
  color: gray !important;
}

input[type="submit"] {
  background-color: #0a2a7b;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  align-self: center;
  margin: 1em;
}

input[type="submit"]:hover {
  background-color: rgb(83, 117, 192);
}

.input-hide-arrow[type="number"]::-webkit-inner-spin-button,
.input-hide-arrow[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-container {
  margin: 10px auto;
  width: 560px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.error-message {
  z-index: 100;
  position: fixed;
  margin: auto;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.7em;
  width: 90%;
  border-radius: 0.25rem;
  opacity: 1;
  transition: opacity 0.6s;
}

.airport-pan-row.airport-pan-row-1 {
  display: flex;
  flex: 1 2;
  height: 100%;
}

#airport-pan {
  height: 100% !important;
  max-width: 100%;
  overflow: hidden;
}

.record input:checked ~ .checkrecord {
  background-color: #0a2a7b;
}

.tooltip {
  width: 255px !important;
}

button.block-btn.print-airport {
  right: 3em;
}

select#resource-type {
  color: white;
}

.checkrecord.radio {
  border-radius: 50%;
}

.airport-pan-row-1.hide {
  display: none;
}

.column {
  float: left;
  width: 100%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.multi-select {
  width: 150px;
  text-align: center;
}

.toolTip .fa {
  color: #fff;
}

.condition {
  overflow: auto;
}

.input-field.col.s4.resize {
  width: 10px !important;
}

div#alert-condition {
  width: 100%;
}

select#attribute {
  text-transform: uppercase;
}

.header {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #e0e0e0;
  width: 100%;
  border-radius: 10px;
}

.multi-select {
  width: 150px;
  text-align: center;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
  text-decoration: none;
  color: white;
}

.dashboard-table-wrapper {
  overflow: auto;
  width: 100%;
  flex: 1;
}

.list {
  display: flex;
  flex: 1;
  font-size: 14px;
}

.width-icon {
  margin-right: 8px;
  text-align: center;
  width: 18px;
}

.sys-param-header {
  display: flex;
  justify-content: space-between;
}

.arrows-button-container {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-right: 10px;
  font-size: 12px;
}

.param-arrow-up {
  position: absolute;
  bottom: 0;
  z-index: 1;

  cursor: pointer;
}

.param-arrow-down {
  position: absolute;
  top: 0;

  cursor: pointer;
}

.sys-param-view {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;
  position: relative;
}

@media screen and (max-width: 992px) {
  .sys-param-view {
    justify-content: flex-end;
  }
}

.main-cards .card {
  box-shadow: none;
}

.box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
}

.box > * {
  display: flex;
  justify-content: flex-start;
  position: relative;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  width: 50%;
}

.image-upload {
  font-size: 0.8em;
  color: grey;
  flex: 1;
  margin: 0 5em;
}

.upload-btn-wrapper {
  cursor: pointer;
  position: relative;
  border: 1px solid white;
  margin: 0.5em 0;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
  width: fit-content;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* Hide the browser's default checkbox */
.record input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkrecord {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1.2px solid #9696ab;
  border-radius: 3px;
}

.checkrecord.disabled,
.checkrecord.disabled:hover,
.record:hover input ~ .checkrecord.disabled {
  background: grey;
  pointer-events: none;
  cursor: auto !important;
}

/* On mouse-over, add a grey background color */
.record:hover input ~ .checkrecord {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.record input:checked ~ .checkrecord {
  background-color: #2196f3;
  cursor: pointer;
}

/* Create the checkrecord/indicator (hidden when not checked) */
.checkrecord:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkrecord when checked */
.record input:checked ~ .checkrecord:after {
  display: block;
}

/* Style the checkrecord/indicator */
.record .checkrecord:not(.radio):after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.title {
  margin-top: 10px;
  font-size: 36px;
}

.section {
  display: flex;
  flex-direction: column;
  background: #202831;
  padding: 20px 30px 20px 30px;
  border-radius: 12px;
  margin-bottom: 24px;
}

.section-disabled {
  background-color: #0f0f0f;
}

.custom-switch-on-off {
  position: absolute;
  top: 255px;
  right: 16px;
}

.custom-switch-on-of-label {
  position: absolute;
  top: 295px;
  right: 35px;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button {
  min-width: 120px;
  padding: 10px 20px;
  color: #d2d7e0;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.button:hover {
  color: white;
  background: rgba(0, 0, 0, 0.2);
}

.button-contained-sys {
  background: #1142bc;
  color: white;
}

.button-contained-sys:hover {
  background: #1142bc;
  opacity: 0.7;
}

.dropdown {
  color: white;
  margin: 0;
  min-width: unset;
  margin-right: 95px;
  font-size: 14px;
  border-bottom: none;
  padding-left: 0px;
}

.radio {
  margin: 1rem 0;
  font-size: 19px;
}

.radio input {
  margin-right: 1rem;
}

.tobt-update-wrapper {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

::-webkit-input-placeholder {
  color: rgba(121, 120, 120, 0.8);
}

.mr-top {
  margin-top: 19px;
}

.pagination {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
}

.pagination ul li button {
  color: white;
}

.dashboard-container {
  padding: 1.5rem 2.5rem 0 7rem;
}

.modal-content .airlines {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  min-height: 0;
}

.input-number {
  background-color: #0e161e;
}

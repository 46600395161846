.search_container input {
    background: rgba(196, 206, 217, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    backdrop-filter: blur(8px);
    padding: 0;
    font-size: 16px;
    cursor: text;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #909FBA;
}

.search-icon{
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.64443 16.3028C4.42226 16.3028 1 12.8805 1 8.65139C1 4.42226 4.42226 1 8.64443 1C12.8666 1 16.2889 4.42226 16.2889 8.65139C16.2889 12.8805 12.8666 16.3028 8.64443 16.3028Z' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.2231 14.2161L18.0001 17.6522' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    height: 17px;
}

::placeholder{
    color:#909FBA;
}
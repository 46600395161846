.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 8px;
}

.header {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}

.actions {
    margin-top: 32px;
}
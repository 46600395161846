.container {
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  text-transform: capitalize;
}

.small {
  font-size: 14px;
  padding: 6px 16px 8px 16px;
}

.large {
  font-size: 14px;
  padding: 12px 18px;
}

.container:hover {
  opacity: 0.8;
  border: 1px solid #ffffff;
}

.primary,
.primary:hover,
.primary:focus {
  color: #ffffff;
  background: #205cf6;
  border-color: #205cf6;
}

.primary-dark,
.primary-dark:hover,
.primary-dark:focus {
  color: #ffffff;
  background: #0a2a7b;
  border-color: #0a2a7b;
}

.secondary,
.secondary:hover,
.secondary:focus {
  color: #333333;
  background: #ffffff;
}

.danger,
.danger:hover,
.danger:focus {
  color: #ffffff;
  border-color: #ff0000;
  background: #ff0000;
}

.text,
.text:hover,
.text:focus {
  background: none;
  border: none;
  color: #909fba;
}

.text-start,
.text-start:hover,
.text-start:focus {
  background: none;
  border: none;
  color: #205cf6;
  display: flex;
  justify-content: flex-start;
  padding: 0 0;
}

.ghost,
.ghost:hover,
.ghost:focus {
  border-color: #ffffff;
  color: #ffffff;
}

#apm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.apm-container {
  background-color: #282828;
}

.apm-svg-container {
  justify-content: center;
  display: flex;
  flex: 1;
  /* background-image: url('/assets/images/apm-track.svg'); */
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: 50%;
  max-height: 45%;
}

.apm-data-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0.5em;
}

.apm-data-checkbox label {
  font-size: 0.7em;
}

.data-log.show {
  display: block;
}

.data-log .logs {
  overflow: auto;
  margin: 0;
}

.wrench-icon {
  position: absolute;
  left: 50%;
  transform: translate(-6px, -8px);
}

.wrench-icon.top-2 {
  top: 55%;
}

.wrench-icon.top-1 {
  top: 45%;
}

.wrench-icon.show {
  opacity: 1;
  color: black;
}

.wrench-icon.hide {
  opacity: 0;
}

#apm1,
#apm2 {
  fill: white;
}

.apm-root {
  padding: 0 !important;
}

.apm-2-arrows {
  position: absolute;
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 55%;
  transform: translateY(-7px);
  z-index: 10;
}

.apm-1-arrows {
  position: absolute;
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 45%;
  transform: translateY(-8px);
}

.direction-arrow-apm-1,
.direction-arrow-apm-2 {
  font-weight: bolder;
  opacity: 0;
}

#apm1-track,
#apm2-track {
  stroke-width: 1.5px;
  stroke: #fff;
  fill: #fff;
}

.station {
  stroke-width: 2px;
}

.svg-container.apm-svg-container {
  position: relative;
}

.apm-status {
  fill: white;
}

.apm-status.alarm {
  stroke: rgb(173, 92, 92);
  fill: rgb(173, 92, 92);
}

.alert {
  stroke: rgb(231, 77, 77) !important;
  stroke-width: 2px !important;
}

.arrows-north {
  opacity: 0;
  color: black;
  animation: goNorth 1.8s linear infinite;
  animation-iteration-count: 6;
  transform-origin: 50% 50%;
}

.arrows-south {
  opacity: 0;
  color: black;
  animation: goSouth 1.8s linear infinite;
  animation-iteration-count: 6;
  transform-origin: 50% 50%;
}

.go-north {
  flex-direction: row-reverse;
}

.go-south {
  flex-direction: row;
}

.arrow-1 {
  animation-delay: 0s;
}

.arrow-2 {
  animation-delay: 0.3s;
}

.arrow-3 {
  animation-delay: 0.6s;
}

.arrow-4 {
  animation-delay: 0.9s;
}

.arrow-5 {
  animation-delay: 1.2s;
}

.arrow-6 {
  animation-delay: 1.5s;
}

.arrow-7 {
  animation-delay: 1.8s;
}

@keyframes goNorth {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(-25px, 0) rotate(0deg);
    opacity: 0;
  }
}

@keyframes goSouth {
  0% {
    transform: translate(0, 0) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: translate(25px, 0) rotate(180deg);
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    color: green;
  }
  100% {
    color: white;
  }
}

@media screen and (max-width: 992px) {
  #maint1-text,
  #maint2-text {
    opacity: 0;
  }

  .svg-container.apm-svg-container {
    background-size: 220%;
  }
}

.widget-header .apm-header {
  font-size: 14px;
  background: #282828;
}

.apm-header {
  padding-left: 22px;
  min-height: 2.5rem;
}

.container-apm-btn {
  display: flex;
  padding-left: 22px;
  margin-top: -12px;
}

.mb-small {
  margin-bottom: 20px;
}

.apm-1-img {
  transform: translate(-7.5px, -7.5px);
}

.apm-2-img {
  transform: translate(-7.5px, -7.5px);
}

.apm-1-move-north-north {
  offset-path: path("M0 0");
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-1-move-north-south {
  offset-path: path("M0 0 L25 27 H240");
  animation: move-south 3s 1 linear;
  animation-fill-mode: forwards;
}

.apm-1-move-north-maint {
  offset-path: path("M0 0 L25 27");
  animation: move-south 3s 1 linear;
  animation-fill-mode: forwards;
}

.apm-1-move-south-south {
  offset-path: path("M240 27");
}

.apm-1-move-south-north {
  offset-path: path("M0 0 L25 27 H240");
  animation: move-north 3s 1 linear;
  animation-fill-mode: forwards;
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-1-move-south-maint {
  offset-path: path("M25 27 H240");
  animation: move-north 3s 1 linear;
  animation-fill-mode: forwards;
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-1-move-maint-north,
.apm-1-move-alarm-north {
  offset-path: path("M0 0 L25 27");
  animation: move-north 3s 1 linear;
  animation-fill-mode: forwards;
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-1-move-maint-south,
.apm-1-move-alarm-south {
  offset-path: path("M25 27 H240");
  animation: move-south 3s 1 linear;
  animation-fill-mode: forwards;
}

.apm-1-move-maint-maint,
.apm-1-move-alarm-maint {
  offset-path: path("M25 27");
}

.apm-2-move-north-north {
  offset-path: path("M0 70");
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-2-move-north-south {
  offset-path: path("M0 70 L25 43 H240");
  animation: move-south 3s 1 linear;
  animation-fill-mode: forwards;
}

.apm-2-move-north-maint {
  offset-path: path("M0 70 L25 43");
  animation: move-south 3s 1 linear;
  animation-fill-mode: forwards;
}

.apm-2-move-south-south {
  offset-path: path("M240 43");
}

.apm-2-move-south-north {
  offset-path: path("M0 70 L25 43 H240");
  animation: move-north 3s 1 linear;
  animation-fill-mode: forwards;
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-2-move-south-maint {
  offset-path: path("M25 43 H240");
  animation: move-north 3s 1 linear;
  animation-fill-mode: forwards;
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-2-move-maint-north,
.apm-2-move-alarm-north {
  offset-path: path("M0 70 L25 43");
  animation: move-north 3s 1 linear;
  animation-fill-mode: forwards;
  transform: translate(7.5px, -7.5px) scale(-1, 1);
}

.apm-2-move-maint-south,
.apm-2-move-alarm-south {
  offset-path: path("M25 43 H240");
  animation: move-south 3s 1 linear;
  animation-fill-mode: forwards;
}

.apm-2-move-maint-maint,
.apm-2-move-alarm-maint {
  offset-path: path("M25 43");
}

@keyframes move-south {
  0% {
    offset-distance: 0;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes move-north {
  0% {
    offset-distance: 100%;
  }
  100% {
    offset-distance: 0;
  }
}
#apm .apm-body {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.apm-container {
  flex: 1;
  min-height: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

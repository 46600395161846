.list_container {
    margin-top: 24px;
}

.item_container {
    display: flex;
}

.list_item_name {
    flex-grow: 8;
}

.item_actions {
    display: flex;
    gap: 12px;
}

.item_action.action_delete {
    color: #C71A30;
}


.item_divider {
    color: rgba(255, 255, 255, 0.32);
    margin-top: 22px;
    margin-bottom: 18px;
}
.toolTip {
  position: relative;
}

.toolTip .tooltiptext {
  display: none;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  font-size: 12px;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.toolTip .tool-tip-text-config-btn {
  display: none;
  width: auto;
  min-width: 117px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  font-size: 12px;
  position: absolute;
  z-index: 1000 !important;
  top: 123%;
  float: right;
  margin-left: -49px;
}

.toolTip .mttt-tooltip {
  top: -300% ;
  margin-left: -70px ;
}

.toolTip:hover .tool-tip-text-config-btn {
  display: inline;
}

.toolTip:hover .tooltiptext {
  display: inline;
}

[data-tooltip-edge="true"] {
  left: -30px !important;
}

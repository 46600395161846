 .date-input-wrapper {
      position: relative;
      width: 170px;
  }
  
  .date-input-icon {
      position: absolute;
      left: 13px;
      top: 14px;
      font-size: 19px;
    }
  
  
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    color: #fff;
    border: 2px solid #000;
    box-shadow: 24px;
    width: 50%;
    max-width: 540px;
    max-height: 80vh;
    overflow-y: auto;
    background: #0B121A;
    border-radius: 20px;
    padding: 20px;
}

.header_container {
    display: flex;
}

.header_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    flex-grow: 8;
}

.header_sub_title {
    align-items: center;
    color: #909FBA;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
}